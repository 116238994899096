export default {
    methods: {
        openLawyerDashboard(value) {
            if (this.isLawyer) {
                return;
            }
            let routeData = this.$router.resolve({
                name: "dashboard-lawyer",
                params: { lawyerId: value.id, lawyerName: value.title }
            });
            window.open(routeData.href, "_blank");
        },
    }
}