<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

import amchartMixins from "./../../_mixins/amchart-mixins";
import BaseChart from "./base-chart";

export default {
  name: "simple-column-chart",
  mixins: [amchartMixins],
  extends: BaseChart,
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    categoryField: {
      type: String,
      default: "country",
    },
    valueField: {
      type: String,
      default: "visits",
    },
  },
  data() {
    return {
      url: "https://www.amcharts.com/demos/simple-column-chart",
    };
  },
  methods: {
    mockup() {
      let data = [
        {
          country: "USA",
          visits: 2025,
          tik: 100,
        },
        {
          country: "China",
          visits: 1882,
          tik: 100,
        },
        {
          country: "Japan",
          visits: 1809,
          tik: 100,
        },
        {
          country: "Germany",
          visits: 1322,
          tik: 100,
        },
        {
          country: "UK",
          visits: 1122,
          tik: 100,
        },
        {
          country: "France",
          visits: 1114,
          tik: 100,
        },
        {
          country: "India",
          visits: 984,
          tik: 100,
        },
        {
          country: "Spain",
          visits: 711,
          tik: 100,
        },
        {
          country: "Netherlands",
          visits: 665,
          tik: 100,
        },
        {
          country: "Russia",
          visits: 580,
          tik: 100,
        },
        {
          country: "South Korea",
          visits: 443,
          tik: 100,
        },
        {
          country: "Canada",
          visits: 441,
          tik: 100,
        },
        {
          country: "Brazil",
          visits: 395,
          tik: 100,
        },
      ];
      return data;
    },
    createChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
      this.chart = chart;

      // Add data
      chart.data = this.getData();

      // Create axes

      var categoryAxis = null;
      if (this.horizontal) {
        categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      } else {
        categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      }

      categoryAxis.dataFields.category = this.categoryField;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      if (!this.horizontal) {
        categoryAxis.renderer.labels.template.rotation = -45;
      }

      if (this.horizontal) {
        categoryAxis.renderer.minGridDistance = 10;
      }

      var valueAxis = null;
      if (this.horizontal) {
        valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      } else {
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      }
      valueAxis.title.text = this.valueText;
      valueAxis.title.fontWeight = 800;

      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.name = this.valueField;

      // chart.addListener("init", function() {
      //   var d = chart.chartData;
      //   for (var i = 0; i < d.length; i++) {
      //     d[i].label.node.style.pointerEvents = "auto";
      //     chart.addEventListeners(d[i].labelSet, d[i]);
      //   }
      // }); 

      series.columns.template.events.on(
        "hit",
        function(ev) {
          this.$emit("click-chart", ev.target.dataItem.dataContext);
        },
        this
      );
      if (this.horizontal) {
        series.dataFields.valueX = this.valueField;
        series.dataFields.categoryY = this.categoryField;
        series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
      } else {
        series.dataFields.valueY = this.valueField;
        series.dataFields.categoryX = this.categoryField;
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      }

      if (this.showPercent) {
        series.columns.template.tooltipText =
          series.columns.template.tooltipText + "%";
      }

      series.columns.template.fillOpacity = 0.8;

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;



      //       series.labels.template.events.on(
      //   'hit',
      //   function(ev) {
      //     alert('clicked on ' + ev.target.dataItem.category + ' with a value of ' + ev.target.dataItem.value);
      // });
    },
  },
};
</script>
