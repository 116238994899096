<template>
  <div>
    <v-card-title>กราฟแท่งแสดงข้อมูลเคสย้อนหลัง 12 เดือน โดยแสดงอัตราส่วนสถานะของเคสระหว่างที่ปิดแล้วกับที่ยังปิด และกราฟเส้น ที่แสดง % ในแต่ละเดือน</v-card-title>
    <StackedColumnChart
      :isMock="false"
      :data="items"
      categoryField="title"
      valueText="จำนวนเคส"
      percentLineValueField="percentage"
      percentLineText="% Success Rate"
      :valueFields="[
      { key: 'closed', name: 'ปิดแล้ว' },
      { key: 'open', name: 'ที่ยังปิดไม่ได้' }]"
    ></StackedColumnChart>
  </div>
</template>


<script>
// import LayeredColumnChart from "./../../components/amcharts/layered-column-chart";
import StackedColumnChart from "./../../components/amcharts/stacked-column-chart";
//import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";

export default {
  name: "chart-twelve-month-compare-open",
  components: {
    StackedColumnChart,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = "";
      if (this.$route.name === "dashboard-management") {
        url = "/dashboard/management/DistinctCaseStatusBy12Months";
        url =
          "/dashboard2/management/DistinctCaseStatusBy12Months?TypeOfDashboard=Compare_Open";
      } else if (this.$route.name === "dashboard-lawyer") {
        url = "/dashboard/lawyer/DistinctCaseStatusBy12Months";
        let lawyerId = this.$route.params.lawyerId;
        if (lawyerId) {
          url += `?lawyerId=${lawyerId}`;
        }
      }

      this.$http.get(url).then((res) => {
        this.items = [];
        this.items = res.items;
      });
    },
  },
};
</script>