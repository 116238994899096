<template>
  <div>
    <v-card-title>กราฟแสดง Trend จำนวนเคสที่รับเข้ามาในแต่ละเดือน</v-card-title>
    <SimpleColumnChart
      :isMock="false"
      :data="items"
      categoryField="title"
      valueField="count"
      valueText="จำนวนเคส"
    ></SimpleColumnChart>
  </div>
</template>


<script>
import SimpleColumnChart from "./../../components/amcharts/simple-column-chart";

export default {
  name: "chart-trend-number-of-case",
  components: {
    SimpleColumnChart,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = "";
      if (this.$route.name === "dashboard-management") {
        url = "/dashboard/management/DistinctCaseStatusBy12Months";
        url =
          "/dashboard2/management/DistinctCaseStatusBy12Months?TypeOfDashboard=NEW_QTY";
      } else if (this.$route.name === "dashboard-lawyer") {
        url = "/dashboard/lawyer/DistinctCaseStatusBy12Months";
        let lawyerId = this.$route.params.lawyerId;
        if (lawyerId) {
          url += `?lawyerId=${lawyerId}`;
        }
      }

      this.$http.get(url).then((res) => {
        this.items = [];
        this.items = res.items;
      });
    },
  },
};
</script>