<template>
  <div>
    <v-card-title>กราฟแสดง performance ของทนายที่ดีที่สุด 10 อันดับแรกจากทั้งหมด</v-card-title>
    <SimpleColumnChart
      :horizontal="true"
      :height="(items.length>0)?30*items.length:500"
      :isMock="false"
      :data="items"
      categoryField="title"
      valueField="percentage"
      :showPercent="true"
      valueText="Percent (%)"
      @click-chart="openLawyerDashboard"
    ></SimpleColumnChart>
  </div>
</template>


<script>
import SimpleColumnChart from "./../../components/amcharts/simple-column-chart";

import { getNamePrefixString } from "./../../js/custom";
import dashboardMixins from "./../../_mixins/dashboard-mixins";

export default {
  name: "chart-lawyer-top-ten",
  mixins: [dashboardMixins],
  components: {
    SimpleColumnChart,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = "/dashboard/management/Top10LawyerPerformanceByClosedCase";
      url = "/dashboard2/management/Top10LawyerPerformanceByClosedCase";

      this.$http.get(url).then((res) => {
        this.items = [];
        res.items.forEach((item) => {
          this.items.push({
            lawyerId: item.id,
            lawyerName: item.title,
            ...item,
          });
        });
      });
    },
  },
};
</script>