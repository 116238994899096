<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-card-title>กราฟแสดงจำนวนเคสที่ยังไม่จบ แยกตามลูกค้า</v-card-title>

            <PieChart
              :isMock="false"
              categoryField="title"
              valueField="count"
              :data="openCasese"
            ></PieChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-card-title>กราฟแสดงจำนวนเคสที่ยังไม่จบ แยกตามลูกค้า (เฉพาะเดือนล่าสุด)</v-card-title>

            <PieChart
              :isMock="false"
              categoryField="title"
              valueField="count"
              :data="latesMonths"
            ></PieChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <ChartTwelveMonthCompareOpen></ChartTwelveMonthCompareOpen>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartProportionOpen></ChartProportionOpen>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <render-input :input="{type:'section',text:'Trend'}"></render-input>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <ChartTrendNumberOfCase></ChartTrendNumberOfCase>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <ChartTrendCaseAccumulate></ChartTrendCaseAccumulate>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartLawyerTopTen></ChartLawyerTopTen>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-card-title>กราฟแสดง performance ของทนายทั้งหมด (กดที่ชื่อทนายเพื่อดูแยกของทนายแต่ละคนได้)</v-card-title>

            <SimpleColumnChart
              :horizontal="true"
              :height="(lawyerPerformance.length>0)?30*lawyerPerformance.length:500"
              :isMock="false"
              :data="lawyerPerformance"
              categoryField="lawyerName"
              valueField="percentage"
              :showPercent="true"
              valueText="Percent (%)"
              @click-chart="openLawyerDashboard"
            ></SimpleColumnChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import PieChart from "./../../components/amcharts/pie-chart-with-legend";
import SimpleColumnChart from "./../../components/amcharts/simple-column-chart";
import LayeredColumnChart from "./../../components/amcharts/layered-column-chart";

import { getNamePrefixString } from "./../../js/custom";
import ChartTrendNumberOfCase from "./chart-trend-number-of-case";
import ChartTrendCaseAccumulate from "./chart-trend-case-accumulate";
import ChartProportionOpen from "./chart-proportion-open";
import ChartTwelveMonthCompareOpen from "./chart-twelve-month-compare-open";
import ChartLawyerTopTen from "./chart-lawyer-top-ten";

import dashboardMixins from "./../../_mixins/dashboard-mixins";

export default {
  mixins: [dashboardMixins],
  components: {
    PieChart,
    SimpleColumnChart,
    LayeredColumnChart,
    ChartTrendNumberOfCase,
    ChartTrendCaseAccumulate,
    ChartProportionOpen,
    ChartTwelveMonthCompareOpen,
    ChartLawyerTopTen,
  },
  data() {
    return {
      openCasese: [],
      latesMonths: [],
      lawyerPerformance: [],
      trendOpenCaseAccumulate: [],
    };
  },
  created() {
    this.getOpenCase();
    this.getCaseLatesMonth();
    this.getLawyerPerformance();
  },
  methods: {
    getOpenCase() {
      let url = "/dashboard/management/OpenCasesByCustomer";
      url = "/dashboard2/management/OpenCasesByCustomer";

      this.$http.get(url).then((res) => {
        this.openCasese = [];
        res.items.forEach((item) => {
          this.openCasese.push({
            title: item.title,
            count: item.count,
            ...item,
          });
        });
      });
    },
    getCaseLatesMonth() {
      let url = "/dashboard/management/OpenCasesLatestMonthByCustomer";
      url = "/dashboard2/management/OpenCasesLatestMonthByCustomer";

      this.$http.get(url).then((res) => {
        this.latesMonths = [];
        res.items.forEach((item) => {
          this.latesMonths.push({
            title: item.title,
            count: item.count,
            ...item,
          });
        });
      });
    },
    getLawyerPerformance() {
      let url = "/dashboard/management/LawyerPerformanceByClosedCase";
      url = "/dashboard2/management/LawyerPerformanceByClosedCase";

      this.$http.get(url).then((res) => {
        this.lawyerPerformance = [];
        res.items.forEach((item) => {
          this.lawyerPerformance.push({
            lawyerId: item.id,
            lawyerName: item.title,
            ...item,
          });
        });

        // this.lawyerPerformance = _.orderBy(
        //   this.lawyerPerformance,
        //   ["performancePercentage"],
        //   ["desc"]
        // );
      });
    },
  },
};
</script>


