
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

import amchartMixins from "./../../_mixins/amchart-mixins";
import BaseChart from "./base-chart";

export default {
  name: "layered-column-chart",
  mixins: [amchartMixins],
  extends: BaseChart,
  props: {
    categoryField: {
      type: String,
      default: "name"
    },
    valueFields: {
      type: Array
    },
    valueText: String
  },
  data() {
    return {
      url: "https://www.amcharts.com/demos/layered-column-chart/"
    };
  },
  methods: {
    mockup() {
      let data = [
        {
          country: "USA",
          year2004: 3.5,
          year2005: 4.2
        },
        {
          country: "UK",
          year2004: 1.7,
          year2005: 3.1
        },
        {
          country: "Canada",
          year2004: 2.8,
          year2005: 2.9
        },
        {
          country: "Japan",
          year2004: 2.6,
          year2005: 2.3
        },
        {
          country: "France",
          year2004: 1.4,
          year2005: 2.1
        },
        {
          country: "Brazil",
          year2004: 2.6,
          year2005: 4.9
        }
      ];
      return data;
    },
    createChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      this.chart = chart;

      // Add data
      chart.data = this.getData();

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = this.categoryField;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.hideOversized = false;
      categoryAxis.renderer.minGridDistance = 30;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = this.valueText;
      valueAxis.title.fontWeight = 800;

      // Create series
      this.valueFields.forEach(field => {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.name = field.name;
        series.dataFields.valueY = field.key;
        series.dataFields.categoryX = this.categoryField;
        series.clustered = false;
        series.tooltipText = field.name + " : [bold]{valueY}[/]";
      });

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      // Add a legend
      chart.legend = new am4charts.Legend();
    }
  }
};
</script>
