<template>
  <div>
    <v-card-title>กราฟแสดงอัตราส่วนของเคสที่ยังไม่ปิด แยกตามสถานะของเคส</v-card-title>
    <PieChart
      :isMock="false"
      :data="items"
      categoryField="title"
      valueField="count"
    ></PieChart>
  </div>
</template>


<script>
import PieChart from "./../../components/amcharts/pie-chart-with-legend";

export default {
  name: "chart-proportion-open",
  components: {
    PieChart,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let url = "";
      if (this.$route.name === "dashboard-management") {
        url = "/dashboard/management/DistinctCaseByStatus";
        url = "/dashboard2/management/DistinctCaseByStatus";
      } else if (this.$route.name === "dashboard-lawyer") {
        url = "/dashboard/lawyer/DistinctCaseByStatus";
        let lawyerId = this.$route.params.lawyerId;
        if (lawyerId) {
          url += `?lawyerId=${lawyerId}`;
        }
      }

      this.$http.get(url).then((res) => {
        this.items = [];
        res.items.forEach((item) => {
          this.items.push({
            title: item.title,
            count: item.count,
            ...item,
          });
        });
      });
    },
  },
};
</script>